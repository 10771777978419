<template>
    <div class="merge-fields-list mx-n3">
        <v-container>                        
            <v-row class="night-light-color">
                <v-col>Include the text in [ ] in the email message box and the corresponding field will be inserted in the email that is generated to each tenant. Please type out the merge field. Copying and Pasting the merge field may cause the function to fail.</v-col>
            </v-row>

            <v-row v-for="(m, i) in mergeFields" :key="i">
                <v-col class="py-0" v-if="m.header"><strong>{{m.label}}</strong></v-col>
                <v-col class="py-0" v-else>{{m.label}}</v-col>
                 <v-col class="py-0 text-right" v-if="!m.header">{{m.token}}</v-col>
            </v-row>
            
        </v-container>
    </div>
</template>
<script>


    export default {
        name: "MergeFields",
        data() {
            return {
                mergeFields: [
                     {
                        label: 'Tenant Payment Link',
                        token: '',
                        header: true
                    },
                    {
                        label: 'Payment Link',
                        token: '[Tenant.PayLink]',
                        header: false
                    },
                    {
                        label: 'Tenant Information',
                        token: '',
                        header: true
                    },
                    {
                        label: 'Tenant First',
                        token: '[Tenant.FirstName]',
                        header: false
                    },
                    {
                        label: 'Tenant Last',
                        token: '[Tenant.LastName]',
                        header: false
                    },
                    {
                        label: 'Tenant Email Address',
                        token: '[Tenant.Email]',
                        header: false
                    },
                    {
                        label: 'Tenant Address 1',
                        token: '[Tenant.Address1]',
                        header: false
                    },
                    {
                        label: 'Tenant Address 2',
                        token: '[Tenant.Address2]',
                        header: false
                    },
                    {
                        label: 'Tenant City',
                        token: '[Tenant.City]',
                        header: false
                    },
                    {
                        label: 'Tenant State',
                        token: '[Tenant.State]',
                        header: false
                    },
                    {
                        label: 'Tenant Country',
                        token: '[Tenant.Country]',
                        header: false
                    },
                    {
                        label: 'Tenant Postal Code',
                        token: '[Tenant.PostalCode]',
                        header: false
                    },
                    {
                        label: 'Tenant Cell Phone',
                        token: '[Tenant.CellPhone]',
                        header: false
                    },
                    {
                        label: 'Tenant Past Due',
                        token: '[Tenant.TotalPastDue]',
                        header: false
                    },
                    {
                        label: 'Tenant Card Name',
                        token: '[Tenant.CardName]',
                        header: false
                    },
                    {
                        label: 'Tenant Card Number',
                        token: '[Tenant.CardNumber]',
                        header: false
                    },
                    {
                        label: 'Tenant Card Exp',
                        token: '[Tenant.CardExpDate]',
                        header: false
                    },
                    {
                        label: 'Tenant Billing Address 1',
                        token: '[Tenant.BillingAddress1]',
                        header: false
                    },
                    {
                        label: 'Tenant Billing Address 2',
                        token: '[Tenant.BillingAddress2]',
                        header: false
                    },
                    {
                        label: 'Tenant Billing City',
                        token: '[Tenant.BillingCity]',
                        header: false
                    },
                    {
                        label: 'Tenant Billing State',
                        token: '[Tenant.BillingState]',
                        header: false
                    },
                    {
                        label: 'Tenant Billing Country',
                        token: '[Tenant.BillingCountry]',
                        header: false
                    },
                    {
                        label: 'Insurance Premium Coverage',
                        token: '[Insurance.PremiumCoverage]',
                        header: false
                    },
                    {
                        label: 'Tenant Insurance Premium',
                        token: '[Tenant.InsurancePremium]',
                        header: false
                    },
                    {
                        label: 'Tenant Insurance Exp Date',
                        token: '[Tenant.InsuranceExpDate]',
                        header: false
                    },
                    {
                        label: 'Tenant Gate Code',
                        token: '[Tenant.GateCode]',
                        header: false
                    },
                    {
                        label: 'Tenant Latest Invoice',
                        token: '[Tenant.LatestInvoice]',
                        header: false
                    },
                    {
                        label: 'Tenant Latest Receipt',
                        token: '[Tenant.LatestReceipt]',
                        header: false
                    },
                    {
                        label: 'Tenant Alternate Contact Information:',
                        token: '',
                        header: true
                    },
                    {
                        label: 'Tenant Alt Name',
                        token: '[Tenant.AltName]',
                        header: false
                    },
                    {
                        label: 'Tenant Alt Address 1',
                        token: '[Tenant.AltAddress1]',
                        header: false
                    },
                    {
                        label: 'Tenant Alt Address 2',
                        token: '[Tenant.AltAddress2]',
                        header: false
                    },
                    {
                        label: 'Tenant Alt City',
                        token: '[Tenant.AltCity]',
                        header: false
                    },
                    {
                        label: 'Tenant Alt State',
                        token: '[Tenant.AltState]',
                        header: false
                    },
                    {
                        label: 'Tenant Alt Country',
                        token: '[Tenant.AltCountry]',
                        header: false
                    },
                    {
                        label: 'Tenant Alt Postal Code',
                        token: '[Tenant.AltPostalCode]',
                        header: false
                    },
                    {
                        label: 'Tenant Business Representative Information:',
                        token: '',
                        header: true
                    },
                    {
                        label: 'Tenant Business Rep Email',
                        token: '[Tenant.BusinessRepresentativeEmail]',
                        header: false
                    },
                    {
                        label: 'Tenant Business Rep Name',
                        token: '[Tenant.BusinessRepresentativeName]',
                        header: false
                    },
                    {
                        label: 'Tenant Business Rep Phone',
                        token: '[Tenant.BusinessRepresentativePhone]',
                        header: false
                    },
                    {
                        label: 'Tenant Business Rep Address 1',
                        token: '[Tenant.BusinessRepresentativeAddress1]',
                        header: false
                    },
                    {
                        label: 'Tenant Business Rep Address 2',
                        token: '[Tenant.BusinessRepresentativeAddress2]',
                        header: false
                    },
                    {
                        label: 'Tenant Business Rep City',
                        token: '[Tenant.BusinessRepresentativeCity]',
                        header: false
                    },
                    {
                        label: 'Tenant Business Rep State',
                        token: '[Tenant.BusinessRepresentativeState]',
                        header: false
                    },
                    {
                        label: 'Tenant Business Rep Country',
                        token: '[Tenant.BusinessRepresentativeCountry]',
                        header: false
                    },
                    {
                        label: 'Tenant Business Rep Postal Code',
                        token: '[Tenant.BusinessRepresentativePostalCode]',
                        header: false
                    },
                    {
                        label: 'Tenant Taxpayer ID',
                        token: '[Tenant.TaxpayerID]',
                        header: false
                    },
                    {
                        label: 'Facility Information',
                        token: '',
                        header: true
                    },
                    {
                        label: 'Facility Name',
                        token: '[Facility.Name]',
                        header: false
                    },
                    {
                        label: 'Facility Legal Name',
                        token: '[Facility.LegalName]',
                        header: false
                    },
                    {
                        label: 'Facility Address 1',
                        token: '[Facility.Address1]',
                        header: false
                    },
                    {
                        label: 'Facility Address 2',
                        token: '[Facility.Address2]',
                        header: false
                    },
                    {
                        label: 'Facility City',
                        token: '[Facility.City]',
                        header: false
                    },
                    {
                        label: 'Facility State',
                        token: '[Facility.State]',
                        header: false
                    },
                    {
                        label: 'Facility Country',
                        token: '[Facility.Country]',
                        header: false
                    },
                    {
                        label: 'Facility Postal Code',
                        token: '[Facility.PostalCode]',
                        header: false
                    },
                    {
                        label: 'Facility Phone',
                        token: '[Facility.Phone]',
                        header: false
                    },
                    {
                        label: 'Facility Email',
                        token: '[Facility.Email]',
                        header: false
                    },
                    {
                        label: 'Facility Website',
                        token: '[Facility.Website]',
                        header: false
                    },
                    {
                        label: 'Facility Late Fee',
                        token: '[Facility.LateFee]',
                        header: false
                    },
                    {
                        label: 'Facility Admin Fee',
                        token: '[Facility.AdminFee]',
                        header: false
                    },
                    {
                        label: 'Space Information',
                        token: '',
                        header: true
                    },
                    {
                        label: 'Space Rate',
                        token: '[Space.Rate]',
                        header: false
                    },
                    {
                        label: 'Space ID',
                        token: '[Space.ID]',
                        header: false
                    },
                    {
                        label: 'Space Size',
                        token: '[Space.Size]',
                        header: false
                    },
                    {
                        label: 'Space Type',
                        token: '[Space.Type]',
                        header: false
                    },
                    {
                        label: 'Tenant Move-In Date',
                        token: '[Tenant.MoveInDate]',
                        header: false
                    },
                    {
                        label: 'Tenant Move-In Cost',
                        token: '[Tenant.MoveInCost]',
                        header: false
                    },
                    {
                        label: 'Tenant Rent Due Date',
                        token: '[Tenant.RentDueDate]',
                        header: false
                    },
                    {
                        label: 'Tenant Rent',
                        token: '[Tenant.Rent]',
                        header: false
                    },
                    {
                        label: 'Tenant Promotions',
                        token: '[Tenant.Promo]',
                        header: false
                    },
                    {
                        label: 'Tenant Discounts',
                        token: '[Tenant.Discount]',
                        header: false
                    },
                    {
                        label: 'Tenant Rent With Tax',
                        token: '[Tenant.RentWithTax]',
                        header: false
                    },
                    {
                        label: 'Tenant New Rent With Tax',
                        token: '[Tenant.NewRentWithTax]',
                        header: false
                    },
                    {
                        label: 'Tenant Monthly Charges',
                        token: '[Tenant.MonthlyCharge]',
                        header: false
                    },
                    {
                        label: 'Tenant New Monthly Charges',
                        token: '[Tenant.NewMonthlyCharge]',
                        header: false
                    },
                    {
                        label: 'Reversal Information',
                        token: '',
                        header: true
                    },
                    {
                        label: 'Reversal Spaces ID',
                        token: '[ReversalSpace.ID]',
                        header: false
                    },
                ]
            }
        }
    }
</script>